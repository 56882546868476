<template>
  <div>
    <v-card-title>
      <span class="headline">Enviar DPS para o Time Técnico</span>
    </v-card-title>
    <v-card-text style="max-height: 455px;" class="overflow-y-auto">
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-text-field label="CNPJ" readonly :value="email.cnpj" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Seguradora" readonly :value="email.insurer" required>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="email.currentPlan"
                :rules="[rules.required]"
                label="Plano Atual*"
              ></v-text-field>
            </v-col>
            <v-col cols="7">
              <v-text-field
                label="Expectativa valor total da fatura mensal *"
                v-model="email.totalMonthlyPremium"
                :rules="[rules.required]"
                prefix="R$"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                label="Plano Fechado *"
                v-model="email.closedPlan"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="email.typeSubscription"
                :items="['Compulsório', 'Adesão/Facultativo']"
                label="Tipo de Contratação *"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="datePicker"
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Início de Vigência *"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="email.startDate" no-title @input="setDate"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email do closer *"
                v-model="email.closerEmail"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="close()">
        Fechar
      </v-btn>
      <v-btn color="green darken-1" text @click="sendEmail()">
        Enviar
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { isEmpty } from "lodash";
import rules from "@/utils/rules";
import { post } from "@/utils/api";
import { insurers } from "@/utils/dps.utils";
import { API } from "aws-amplify";
import { createSentEmails } from "@/graphql/mutations";
import { updateStep } from "@/components/ChangeStep/update-step";

export default {
  name: "TechTeam",
  props: ["toggle", "items"],
  mounted() {
    if (!isEmpty(this.items)) {
      this.email.cnpj = this.items[0].cnpj;
      const insurer = this.items[0].insurer || 'unimed'
      this.email.insurer = insurers.find(x => x.value == insurer)?.text
    }
  },
  data() {
    return {
      email: {
        cnpj: "",
        insurer: "",
        currentPlan: "",
        totalMonthlyPremium: "",
        closedPlan: "",
        typeSubscription: "",
        startDate: "",
        closerEmail: ""
      },
      date: "",
      datePicker: false,
      rules: rules
    };
  },
  methods: {
    close() {
      if (this.toggle) this.toggle();
    },
    setDate(date) {
      this.email.startDate = date;
      this.date = this.parseDate(date);

      this.datePicker = false;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.toString().split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    async sendEmail() {
      if (this.$refs.form.validate()) {
        try {
          this.$store.commit("loading", true);
          await post({ path: "/email", body: { ...this.email } });
          await API.graphql({
            query: createSentEmails,
            variables: {
              input: this.email
            }
          });

          const updateItems = this.items.filter(
            item => item.step === "Comercial" || !item.step || item.step === ""
          );

          const promises = [];
          for (const item of updateItems) {
            item.step = "Em Análise Técnica";

            promises.push(updateStep({ item, items: updateItems }));
          }

          await Promise.all(promises);
          this.$store.commit("showAlert", {
            type: "success",
            message: "Email enviado com sucesso"
          });
          this.close();
        } catch (error) {
          console.error(error);
          this.$store.commit("showAlert", {
            type: "error",
            message: "Não foi possível enviar o email"
          });
        } finally {
          this.$store.commit("loading", false);
        }
      }
    }
  }
};
</script>
