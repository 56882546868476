/* eslint-disable */
import { Auth, API } from 'aws-amplify';
import { getSentEmails } from '../../graphql/queries';
import { updateDps } from '../../graphql/mutations';
import { post } from '../../utils/api';
import store from '../../store';
import { isEmpty } from 'lodash';

const sendRefusedEmail = async (cnpj) => {
  const { data } = await API.graphql({
    query: getSentEmails,
    variables: {
      cnpj,
    },
  });

  if (!data.getSentEmails) {
    store.commit('showAlert', { type: 'error', message: 'Conta sem email de closer atrelado' });
  } else {
    const body = {
      cnpj,
      userName: store.state.user.name,
      to: data.getSentEmails.closerEmail,
    };

    await post({ path: '/emailRefused', body });
  }
};

const sendApprovedEmail = async (cnpj, items) => {
  const { data } = await API.graphql({
    query: getSentEmails,
    variables: {
      cnpj,
    },
  });

  if (!data.getSentEmails) {
    store.commit('showAlert', { type: 'error', message: 'Conta sem email de closer atrelado' });
  } else {
    const hasCPT = items.some((x) => !isEmpty(x.cpt));
    const body = {
      cnpj,
      userName: store.state.user.name,
      to: data.getSentEmails.closerEmail,
      hasCPT,
    };

    await post({ path: '/emailApproved', body });
  }
};

const sendApprovedCptEmail = async (cnpj, items) => {
  try {
    if (items.length > 0) {
      let body = items
        .filter((el) => !isEmpty(el.cpt))
        .map((x) => ({
          id: x.id,
          email: x.email,
          cpt: x.cpt,
          cnpj: x.cnpj,
          name: `${x.name} ${x.lastName}`,
          document: x.cpf,
          insurer: x.insurer || 'unimed',
        }));

      post({ path: '/email-cpt', body });
    }
  } catch (error) {
    store.commit('showAlert', {
      type: 'error',
      message: 'Não foi possível enviar o email CPT',
    });
  }
};

const updateStep = async ({ item, items }) => {
  try {
    const promises = [];
    store.commit('loading', true);

    await API.graphql({
      query: updateDps,
      variables: {
        input: item,
      },
    });

    if (item.step === 'Recusado') {
      promises.push(sendRefusedEmail(item.cnpj));
    }

    const approvedCompany = item.step === 'Aprovado' && items.every((el) => el.step === 'Aprovado');
    if (approvedCompany) {
      promises.push(sendApprovedEmail(item.cnpj, items));

      if (store.state.user.isHealthTeam) {
        promises.push(sendApprovedCptEmail(item.cnpj, items));
      }
    }
    await Promise.all(promises);

    if (approvedCompany) {
      store.commit('showAlert', {
        type: 'success',
        message: 'Empresa aprovada com sucesso',
      });
    }
  } catch (error) {
    store.commit('showAlert', {
      type: 'error',
      message: 'Erro ao processar solicitação',
    });
  } finally {
    store.commit('loading', false);
  }
};

export { updateStep };
